import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

export interface MsmVicharanSevaRendererProps {
    sevaId: string;
    sevaName: string;
    onSevaAdded: (sevaId: string, count: number) => void;
    onSevaUpdated: (sevaId: string, count: number) => void;
    onSevaDeleted: (sevaId: string) => void;
}

export default function MsmVicharanSevaRenderer(props: MsmVicharanSevaRendererProps) {

    const [isSevaSelected, setIsSevaSelected] = useState<boolean>(false);
    const [sevaCount, setSevaCount] = useState<number>(0);

    const onSevaSelectedChanged = () => {
        const newValue = !isSevaSelected;

        if(newValue)
        {
            setSevaCount(1);
            props.onSevaAdded(props.sevaId, 1);
        }
        else
        {
            setSevaCount(0);
            props.onSevaDeleted(props.sevaId);
        }
        setIsSevaSelected(newValue);
    }

    const handleSevaCountChanged = (e: any) => {
        const newSevaCount: number = e.target.value;
        setSevaCount(newSevaCount);
        props.onSevaUpdated(props.sevaId, newSevaCount);
    }

    return (<Row>
        <Col md="8" sm="12">
            <input type="checkbox" checked={isSevaSelected}  onChange={onSevaSelectedChanged}></input>&nbsp;&nbsp;{props.sevaName}
        </Col>
        <Col md="4" sm="12">
            <Form.Group>
                <Form.Control disabled={!isSevaSelected} as="select" placeholder="Select Seva Count" value={sevaCount} onChange={handleSevaCountChanged}>
                    <option value={0} disabled>Select Seva Count</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                </Form.Control>
            </Form.Group>
        </Col>
    </Row>)
}