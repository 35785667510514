import React from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import CustomFieldValue from "../models/CustomFieldValue";
import Event from "../models/Event";
import MsmVicharanSevaRenderer from "./MsmVicharanSevaRenderer";
import "./msmVicharanStyles.css";
import AllowAnyAmountEventRenderer from "./AllowAnyAmountEventRenderer";

export interface MsmVicharanEventRendererProps {
    events: Event[],
    updateSevaDetailsForEvent: (
        sevaCount: number,
        customFields: Array<CustomFieldValue>,
        event: Event,
        sevaAmount?: number) => void;
}

export default function MsmVicharanEventRenderer(props: MsmVicharanEventRendererProps) {

    const onSevaAdded = (sevaId: string, count: number) => {
        const matchingEventIndex = props.events.findIndex(e => e.name === sevaId);
        props.updateSevaDetailsForEvent(count, [], props.events[matchingEventIndex]);
    }

    const onSevaUpdated = (sevaId: string, count: number) => {
        const matchingEventIndex = props.events.findIndex(e => e.name === sevaId);
        props.updateSevaDetailsForEvent(count, [], props.events[matchingEventIndex]);
    }

    const onSevaDeleted = (sevaId: string) => {
        const matchingEventIndex = props.events.findIndex(e => e.name === sevaId);
        props.updateSevaDetailsForEvent(0, [], props.events[matchingEventIndex]);
    }

    const onAnyAmountSevaDetailsChanged = (sevaCount: number,
            customFields: Array<CustomFieldValue>,
            event: Event,
            sevaAmount?: number) =>
    {
        props.updateSevaDetailsForEvent(sevaCount, [], event, sevaAmount);
    }

    const anyAmountSevaEvent = props.events.find(e => e.allowAnyAmount);

    /*
        Mahotsav-Sabha-Sponsor-Akshardham-Bhoomi-Pujan
        Mahotsav-Sabha-Sponsor-Tapomurti-Shri-Nilkanth-Varni-Maharaj
        Mahotsav-Sabha-Sponsor-Shikharbaddh-Mandir-Murti-Pratistha
        Mahotsav-Sabha-Sponsor-Shikharbaddh-Mandir-Shilanyas
        Mahotsav-Sabha-Sponsor-Fuldol
        Mahotsav-Sabha-Sponsor-Maha-Annakut
        Mahotsav-Sabha-Sponsor-Shri-Swaminarayan-Mahayagna
    */
    const renderMahotsavSabhaSponsorship = () => {

        const mahotsavSevas =
            [
                { Name: "Akshardham Bhumi Pujan @ Melbourne (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Akshardham-Bhoomi-Pujan" },
                /*{ Name: "Tapomurti Shri Nilkanth Varni Pratishtha @ Sydney (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Tapomurti-Shri-Nilkanth-Varni-Maharaj" },*/
                { Name: "Sydney Shikharbaddha Mandir Murti Pratishtha (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Shikharbaddh-Mandir-Murti-Pratistha" },
                { Name: "Sydney Shikharbaddha Mandir Shilanyas Vidhi (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Shikharbaddh-Mandir-Shilanyas" },
                { Name: "Fuldol (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Fuldol" },
                { Name: "Shri Swaminarayan Vishwashanti Mahayagna (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Shri-Swaminarayan-Mahayagna" },
            ].map(function (item) {
                return { value: item.Id, label: item.Name };
            });

        return (<Container>
            <Row>
                <Col md={12} style={{ textAlign: "center" }} className="mb-1">
                    <h2><Badge variant="warning">Mahotsav Sabha Sponsorship</Badge></h2>
                </Col>
            </Row>

              {
                mahotsavSevas.map(eachSeva => {
                    return (<MsmVicharanSevaRenderer sevaId={eachSeva.value} 
                                                     sevaName={eachSeva.label}  
                                                     onSevaAdded={onSevaAdded}
                                                     onSevaDeleted={onSevaDeleted}
                                                     onSevaUpdated={onSevaUpdated}
                                                     />)
                })
              }
        </Container>);
    }

    /*
        Utsav-Sabha-Sponsor-Swagat-Sabha-Sydney
Utsav-Sabha-Sponsor-Swagat-Sabha-Melbourne
Utsav-Sabha-Sponsor-Satsang-Diksha-Janmotsav
Utsav-Sabha-Sponsor-Diksha-Mahotsav
Utsav-Sabha-Sponsor-Guruhari-Bhakti-Tula
Utsav-Sabha-Sponsor-Nakkar-Vidvan-Grand-Falicitation
Utsav-Sabha-Sponsor-Nagar-Yatra

 1.Swagat Sabha 
 2.Satsang Diksha Janomtsav 
 3. Diksha Mahotsav 
 4. Guruhari Bhakti Tula 
 5.Nakkar Vidvan Grand Falicitation 
 6. Nagar Yatra 

    */
    const renderUtsavSabhaSponsorship = () => {
        const utsavSevas = [
            { Name: "Swagat Sabha @ Melbourne (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Swagat-Sabha-Melbourne" },
            { Name: "Swagat Sabha @ Sydney (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Swagat-Sabha-Sydney" },
            { Name: "Satsang Diksha Janmotsav @ Melbourne (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Satsang-Diksha-Janmotsav" },
            { Name: "Diksha Mahotsav @ Melbourne (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Diksha-Mahotsav" },
            { Name: "Guruhari Bhakti Tula @ Melbourne (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Guruhari-Bhakti-Tula" },
            { Name: "Nakkar Vidwan Grand Falicitation @ Melbourne (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Nakkar-Vidvan-Grand-Falicitation" },
            { Name: "Nagar Yatra @ Sydney (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Nagar-Yatra" },]
            .map(function (item) {
                return { value: item.Id, label: item.Name };
            });

        return (
            <Container>
            <Row>
                <Col md={12} style={{ textAlign: "center" }} className="mb-1">
                    <h2><Badge variant="warning">Utsav Sabha Sponsorship</Badge></h2>
                </Col>
            </Row>

              {
                utsavSevas.map(eachSeva => {
                    return (<MsmVicharanSevaRenderer sevaId={eachSeva.value} 
                                                     sevaName={eachSeva.label}  
                                                     onSevaAdded={onSevaAdded}
                                                     onSevaDeleted={onSevaDeleted}
                                                     onSevaUpdated={onSevaUpdated}
                                                     />)
                })
              }
        </Container>);
    }

    const miscSevaItems = [
        { "SevaId": "Other-Akshar-Purushottam-Maharaj-Thal", "SevaName": "Shri Akshar Purushottam Maharaj Thal / Day", "SevaAmount": "250" },
        { "SevaId": "Other-Param-Pujya-Mahant-Swami-Dhotiya-Seva", "SevaName": "Dhotiya Seva - P.P. Mahant Swami Maharaj", "SevaAmount": "500" },
        { "SevaId": "Other-Sant-Rasoi", "SevaName": "Sant Rasoi / Day", "SevaAmount": "751" },
        { "SevaId": "Other-Satsang-Sabha-Sponsor-Sydney", "SevaName": "Satsang Sabha Sponsor @ Sydney", "SevaAmount": "1,100" },
        { "SevaId": "Other-Satsang-Sabha-Sponsor-Melbourne", "SevaName": "Satsang Sabha Sponsor @ Melbourne", "SevaAmount": "1,100" },
        { "SevaId": "Other-Param-Pujya-Mahant-Swami-And-All-Santo-Dhotiya-Seva", "SevaName": "Dhotiya Seva - P.P. Mahant Swami Maharaj and all Vicharan Santo", "SevaAmount": "2,500" }];

    const renderOtherMiscSeva = () => {

        const otherSevas = miscSevaItems.map(function (item) {
            return { value: item.SevaId, label: `${item.SevaName} - A$${item.SevaAmount}` };
        });

        return (
            <Container>
                <Row>
                    <Col md={12} style={{ textAlign: "center" }} className="mb-1">
                        <h2><Badge variant="warning">Other Donation Seva</Badge></h2>
                    </Col>
                </Row>
                {
                    otherSevas.map(eachSeva => {
                        return (<MsmVicharanSevaRenderer sevaId={eachSeva.value}
                            sevaName={eachSeva.label}
                            onSevaAdded={onSevaAdded}
                            onSevaDeleted={onSevaDeleted}
                            onSevaUpdated={onSevaUpdated}
                        />)
                    })
                }
            </Container>);
    }

    const murtiPratishthaSevaItems = [
        { "SevaId": "Sydney-Shikharbaddh-Shri-Nilkanthvarni-Abhishek-Murti", "SevaName": "Shri Nilkanthvarni Abhishek Murti", "SevaAmount": "11,000" },
        { "SevaId": "Sydney-Shikharbaddh-Sinhasan-Seva", "SevaName": "Sinhasan Seva", "SevaAmount": "25,000" },
        { "SevaId": "Sydney-Shikharbaddh-Shri-Akshar-Purushottam-Maharaj-Murti-Seva", "SevaName": "Shri Akshar Purushottam Maharaj Murti Seva ", "SevaAmount": "21,000" },
        { "SevaId": "Sydney-Shikharbaddh-Shri-Ghanshayam-Maharaj-Murti-Seva", "SevaName": "Shri Ghanshyam Maharaj Murti Seva", "SevaAmount": "15,000" },
        { "SevaId": "Sydney-Shikharbaddh-Bhagvan-Shri-Ram-Parivar", "SevaName": "Bhagwan Shri Ram Parivar", "SevaAmount": "15,000" },
        { "SevaId": "Sydney-Shikharbaddh-Bhagvan-Shri-Shankar-Parivar", "SevaName": "Bhagwan Shri Shankar Parivar", "SevaAmount": "15,000" },
        { "SevaId": "Sydney-Shikharbaddh-Bhagvan-Shri-Krishna-and-Shri-Radhikaji", "SevaName": "Bhagwan Shri Krishna and Shri Radhikaji", "SevaAmount": "15,000" }];

    const rednerMurtiPratishthaSevaItems = () => {

        const murtiSevas = murtiPratishthaSevaItems.map(function (item) {
            return { value: item.SevaId, label: `${item.SevaName} - A$${item.SevaAmount}` };
        });

        return (
            <Container>
                <Row>
                    <Col md={12} style={{ textAlign: "center" }} className="mb-1">
                        <h2><Badge variant="warning">Sydney Shikharbaddha Mandir  Murti Seva </Badge></h2>
                    </Col>
                </Row>
                {
                    murtiSevas.map(eachSeva => {
                        return (<MsmVicharanSevaRenderer sevaId={eachSeva.value}
                            sevaName={eachSeva.label}
                            onSevaAdded={onSevaAdded}
                            onSevaDeleted={onSevaDeleted}
                            onSevaUpdated={onSevaUpdated}
                        />)
                    })
                }
            </Container>);
    }

    const usaSevaAmounts = [
        { "SevaId": "Sydney-Shikharbaddh-Shri-Akshar-Purushottam-Maharaj-Murti-Seva-USA",  "SevaName": "Shri Akshar Purushottam Maharaj Murti Seva (USA Only)", "SevaAmount": "1,001" }]

    const renderUsaMurtiSeva = () => {

        const murtiSevaUSA = usaSevaAmounts.map(function (item) {
            return { value: item.SevaId, label: `${item.SevaName} - A$${item.SevaAmount}` };
        });

        return (
            <Container>
                <Row>
                    <Col md={12} style={{ textAlign: "center" }} className="mb-1">
                        <h2><Badge variant="warning">Sydney Shikharbaddha Mandir  Murti Seva (for the USA Donors Only) </Badge></h2>
                    </Col>
                </Row>
                {
                    murtiSevaUSA.map(eachSeva => {
                        return (<MsmVicharanSevaRenderer sevaId={eachSeva.value}
                            sevaName={eachSeva.label}
                            onSevaAdded={onSevaAdded}
                            onSevaDeleted={onSevaDeleted}
                            onSevaUpdated={onSevaUpdated}
                        />)
                    })
                }
            </Container>);
    }

    const annakutSevaAmounts = [
        { "SevaId": "Annakut-2025-Bhav-Seva",  "SevaName": "Annakut Bhav Seva", "SevaAmount": "5000" },
        { "SevaId": "Annakut-2025-Shri-Akshar-Purushottam-Khand-Thal-Ni-Seva",  "SevaName": "Shri Akshar Purushottam Khand Thal Ni Seva", "SevaAmount": "1500" },
        { "SevaId": "Annakut-2025-Thal-Ni-Seva",  "SevaName": "Thal Ni Seva", "SevaAmount": "251" }
    ];

    const renderAnnakutSeva = () => {

        const annakutSeva = annakutSevaAmounts.map(function (item) {
            return { value: item.SevaId, label: `${item.SevaName} - A$${item.SevaAmount}` };
        });

        return (
            <Container>
                <Row>
                    <Col md={12} style={{ textAlign: "center" }} className="mb-1">
                        <h2><Badge variant="warning">Annakut Seva</Badge></h2>
                    </Col>
                </Row>
                {
                    annakutSeva.map(eachSeva => {
                        return (<MsmVicharanSevaRenderer sevaId={eachSeva.value}
                            sevaName={eachSeva.label}
                            onSevaAdded={onSevaAdded}
                            onSevaDeleted={onSevaDeleted}
                            onSevaUpdated={onSevaUpdated}
                        />)
                    })
                }
            </Container>);
    }


    return (<>
        <div id="sevacardcontainers">
           {/* 
            <Row>
                <Col sm="12" md="12">
                    {renderMahotsavSabhaSponsorship()}
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="12">
                    {renderUtsavSabhaSponsorship()}
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="12">
                    {rednerMurtiPratishthaSevaItems()}
                </Col>
            </Row>*/}
            <Row>
                <Col sm="12" md="12">
                    {renderAnnakutSeva()}
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="12">
                    {renderOtherMiscSeva()}
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="12">
                    {renderUsaMurtiSeva()}
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="12">
                    {anyAmountSevaEvent &&
                        (<AllowAnyAmountEventRenderer
                            updateSevaDetailsForEvent={onAnyAmountSevaDetailsChanged}
                            minDonationAmount={500}
                            event={anyAmountSevaEvent}
                        />)
                    }
                </Col>
            </Row>
        </div>
    </>);
}